import request from '@/utils/request'
export function initNewsData (data) {
  return request({
    url: '/ErrorCorrectionFeedback/selectAllErrorCorrectionFeedback',
    method: 'POST',
    data
  })
}
export function showData (data) {
  return request({
    url: '/data/showData',
    method: 'POST',
    data
  })
}
export function getNodePermission (data) {
  return request({
    url: '/data/getNodePermission',
    method: 'POST',
    data
  })
}
// 导出
export function dataExcelExport (data) {
  return request({
    url: '/data/alldataExcelExport',
    method: 'POST',
    data
  })
}

export function showOneNewsData (data) {
  return request({
    url: '/data/showOneNewsData',
    method: 'POST',
    data
  })
}
export function listAllSiteType (data) {
  return request({
    url: '/data/listSiteType',
    method: 'POST',
    data
  })
}
export function batchUpdateEmotion (data) {
  return request({
    url: '/data/batchUpdateEmotion',
    method: 'POST',
    data
  })
}
export function batchUpdateSummary (data) {
  return request({
    url: '/data/batchUpdateSummary',
    method: 'POST',
    data
  })
}
export function batchSetIsTag (data) {
  return request({
    url: '/data/batchSetIsTag',
    method: 'POST',
    data
  })
}
export function batchDeleteNews (data) {
  return request({
    url: '/data/batchDeleteNews',
    method: 'POST',
    data
  })
}
// 列表删除新闻接口
export function newBatchDeleteNews (data) {
  return request({
    url: '/data/newBatchDeleteNews',
    method: 'POST',
    data
  })
}
export function deleteAndNotIncludeSon (data) {
  return request({
    url: '/data/deleteAndNotIncludeSon',
    method: 'POST',
    data
  })
}
export function deleteMsMap (data) {
  return request({
    url: '/data/deleteMsMap',
    method: 'POST',
    data
  })
}
export function batchUpdateSource (data) {
  return request({
    url: '/data/batchUpdateSId',
    method: 'POST',
    data
  })
}
export function listChildrenNode (data) {
  return request({
    url: '/data/listChildrenNode',
    method: 'POST',
    data
  })
}
export function listAllNodeWithMS (data) {
  return request({
    url: '/data/listAllNodeWithMS',
    method: 'POST',
    data
  })
}
export function setSubject (data) {
  return request({
    url: '/data/setSubject',
    method: 'POST',
    data
  })
}
export function customSource (data) {
  return request({
    url: '/data/customSource',
    method: 'POST',
    data
  })
}
export function save (data) {
  return request({
    url: '/data/save',
    method: 'POST',
    data
  })
}
export function update (data) {
  return request({
    url: '/data/update',
    method: 'POST',
    data
  })
}
export function addNewsGroup (data) {
  return request({
    url: '/data/addNewsGroup',
    method: 'POST',
    data
  })
}
export function showSonData (data) {
  return request({
    url: '/data/showSonData',
    method: 'POST',
    data
  })
}
export function showLikeSonData (data) {
  return request({
    url: '/data/showLikeSonData',
    method: 'POST',
    data
  })
}
export function removeMainNews (data) {
  return request({
    url: '/data/removeMainNews',
    method: 'POST',
    data
  })
}
export function delSonNewsForSN (data) {
  return request({
    url: '/data/delSonNewsForSN',
    method: 'POST',
    data
  })
}
export function delMainNewForSN (data) {
  return request({
    url: '/data/delMainNewForSN',
    method: 'POST',
    data
  })
}
export function listAllNewsGroup (data) {
  return request({
    url: '/data/listAllNewsGroup',
    method: 'POST',
    data
  })
}
export function exportWord (data) {
  return request({
    url: '/data/exportWord',
    method: 'POST',
    data
  })
}
export function allexportWord (data) {
  return request({
    url: '/data/allexportWord',
    method: 'POST',
    data
  })
}
export function reClass (data) {
  return request({
    url: '/cus/reClass',
    method: 'POST',
    data
  })
}
export function stopReClassTask () {
  return request({
    url: '/cus/stopReClassTask',
    method: 'POST'
  })
}
export function getNewsDetailsThroughSns (data) {
  return request({
    url: '/data/getNewsDetailsThroughSns',
    method: 'POST',
    data
  })
}

export function eventEvolutionNewsDetails (data) {
  return request({
    url: '/data/eventEvolutionNewsDetails',
    method: 'POST',
    data
  })
}

export function setAsDailys (data) {
  return request({
    url: '/data/setAsDailys',
    method: 'POST',
    data
  })
}
// 取消设为日报
export function cancelCreationOfDailyReportBySn (data) {
  return request({
    url: '/data/cancelCreationOfDailyReportBySn',
    method: 'POST',
    data
  })
}
// 设为主新闻
export function updataMainNews (data) {
  return request({
    url: '/data/updataMainNews',
    method: 'POST',
    data
  })
}
export function sourceCount (data) {
  return request({
    url: '/data/sourceCount',
    method: 'POST',
    data
  })
}
export function videoSiteCount (data) {
  return request({
    url: '/data/videoSiteCount',
    method: 'POST',
    data
  })
}
export function setGroupEmotion (data) {
  return request({
    url: '/data/setGroupEmotion',
    method: 'POST',
    data
  })
}
export function sourceCountWithFolder (data) {
  return request({
    url: '/favorite_folder/sourceCount',
    method: 'POST',
    data
  })
}
export function listSonData (data) {
  return request({
    url: '/favorite_folder/listSonData',
    method: 'POST',
    data
  })
}
// 原创转发
export function updateForwardStatus (data) {
  return request({
    url: '/data/updateDateOriginalForwardingStatus',
    method: 'POST',
    data
  })
}
export function getAIPrompt (data) {
  return request({
    url: '/sys/menu/getAIPrompt',
    method: 'POST',
    data
  })
}
export function addRealTimeUpdate (data) {
  return request({
    url: '/realTime/add',
    method: 'POST',
    data
  })
}
export function addFastSpiderInfo (data) {
  return request({
    url: '/cus/addFastSpiderInfo',
    method: 'POST',
    data
  })
}
